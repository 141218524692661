import React from 'react';
import { GatsbyLinkButton } from '@talves/gatsby-theme-components-tui';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { default as components } from '../../components';
import { useThemeUI, useColorMode } from '../../provider';

const { Box } = components;

const PostsListItem = ({ data, returnPath = '/' }) => {
  const { theme } = useThemeUI();
  const [colorMode] = useColorMode();
  console.log(theme, colorMode);
  return (
    <Box>
      <GatsbyLinkButton to={returnPath}>Return to Portfolio</GatsbyLinkButton>
      <Box as={'h1'} sx={{ mt: '20px' }}>
        {data.post.frontmatter.title}
      </Box>
      <Box
        sx={{
          fontSize: [1, 2, 3],
          '& p': { pl: '20px', mb: ['15px', '20px', '30px'] },
          'h1,h2,h3,h4': {
            mb: '1rem',
          },
          '& table': {
            p: 20,
            '& tbody': {
              '& tr': {
                '& td': { pt: '5px', pb: '5px', pr: '10px', px: '10px' },
              },
            },
          },
          '& blockquote': {
            ml: 20,
            color: `${
              colorMode === 'dark' ? theme.colors.background : theme.colors.text
            }`,
            backgroundColor: `${theme.colors.muted}`,
            borderRadius: 5,
            p: 2,
            mb: 2,
            '& p': { pl: '10px', mb: 0 },
          },
          ul: { pl: '40px', pb: ['15px', '20px', '30px'] },
          hr: { mb: 25 },
          '& a': {
            appearance: 'none',
            border: 0,
            borderRadius: '0.325em',
            bg: 'primary',
            color: 'navtext',
            minWidth: '6.5em',
            padding: '0.5em 1em',
            fontFamily: 'inherit',
            fontSize: ['0.8rem', '0.9rem', '1rem'],
            cursor: 'pointer',
            transition: '0.3s ease-out',
            textDecoration: 'none',
            '&:hover': {
              bg: 'secondary',
            },
            '&:focus': {
              outline: 'none',
              boxShadow: `0 0 0 2px #cccccc`,
            },
            '&:disabled': {
              color: 'background',
              bg: 'muted',
              cursor: 'not-allowed',
            },
          },
        }}
      >
        <MDXProvider components={components}>
          <MDXRenderer>{data.post.parent.body}</MDXRenderer>
        </MDXProvider>
      </Box>
      {false && <Box as="pre">{JSON.stringify(data, null, 2)}</Box>}
    </Box>
  );
};

export default PostsListItem;
